@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

body{
    background-color: #F1ECEB;
    /* background: url(/public/assets/bg.svg) no-repeat; */
    background-size: cover;
    font-family: "Varela", sans-serif;

}
.navbar {
    background-color: #333;
    color: #fff;
    padding: 10px;
}
.homebg{
    background: url(/public/assets/homebg.svg) no-repeat;
    background-size: contain;

}

/* .bg-global{
    background: url(/public/assets/bg-global.svg) no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
} */
.navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar li {
    display: inline-block;
    margin-right: 10px;
}

.navbar a {
    color: #fff;
    text-decoration: none;
}
.tab_active{
    /* border-radius: 6px; */
border: 2px solid #CC523F;
background: #F4BFAE;
color: #FD6039;
}
.tab_inactive{
    /* border-radius: 6px; */
border: 2px solid #000;
}
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate-infinite {
    animation: rotate infinite 25s linear;
  }
  .rotate-infinite-fast {
    animation: rotate infinite 5s linear;
  }
.my-shadow{
    filter: drop-shadow(4px 8px 1px #B6B2B1);
}
  .linear-height-transition{
    height: 1px; /* Initial height */
    animation: increaseHeight 5s ease forwards;
  }

  @keyframes increaseHeight {
    to {
      height:100% /* Final height */
    }
  }
*{
    -webkit-tap-highlight-color: transparent;
}
.transition-opacity2 {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}



.custom-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  transition: opacity 0.5s ease-in-out;
}

.carousel-item {
  flex: 0 0 100%;
  opacity: 0;
}

.carousel-item.opacity-100 {
  opacity: 1;
}
.blinking-text {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0%, 100% {
    opacity: 1;
  }
  70% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
}













/* footer css*/